import './index.scss';
import React, { useEffect, Suspense } from 'react';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import Skeleton from 'react-loading-skeleton';
import ErrorFallback from './components/ErrorFallback';
import Login from './components/Login';
import Header from './common/Header';
import Footer from './common/Footer';
import HubVerify from './components/HubVerify';
import UserLogin from './components/UserLogin';
import UserSignup from './components/SignUp';
import SignUpEmail from './components/SignUpEmail';
import UserEmail from './components/UserEmail';
import SideNav from './common/SideNav';
import Error404Page from '../Error404Page';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const Application = React.lazy(() => import('./components/Application'));
const ApplicationList = React.lazy(() => import('./components/ApplicationList'));

function errorResponseHandler(error) {
  if (error.response) {
    const { status } = error.response;
    if (status !== 401) {
      return Promise.reject(error);
    }
  }
}

function PageLoader() {
  return (
    <div className="container">
      <Skeleton style={{ width: '100%', minHeight: '500px', margin: '30px auto' }} />
    </div>
  );
}

// Custom interceptor for responses
// TODO: Evaluate whether this is still needed
axios.interceptors.response.use(
  response => response,
  errorResponseHandler
);

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { token } = useSelector((state) => state.auth);
  const { appId } = useSelector((state) => state.endUser);
  const { history } = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { redirectTo: location.pathname }
      });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, appId, history, location]);

  const render = props =>
    token && isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { noRedirect } = rest;
  const { isAuthenticated } = useAuth0();
  const { appId } = useSelector((state) => state.endUser);
  return <Route
    {...rest}
    render={(props) =>
      isAuthenticated && !noRedirect ? (
        <Redirect to={{ pathname: appId ? `${process.env.PUBLIC_URL}/apps/${appId}` : `${process.env.PUBLIC_URL}/apps`, state: { from: props.location } }} />
      ) : (
        <Component module="user" {...props} />
      )
    }
  />;
};

function EndUser(props) {

  const history = useHistory();
  const { pathname } = history.location

  const { isAuthenticated } = useAuth0();

  const noHeaderPaths = [
    '/auth/hub/verify'
  ];
  const noFooterPaths = [
    '/auth/hub/verify'
  ];
  const noSideNavPaths = [
    '/auth/hub/verify'
  ];

  const showHeader = !noHeaderPaths.includes(pathname);
  const showFooter = !noFooterPaths.includes(pathname);
  const showSideNav = isAuthenticated && !noSideNavPaths.includes(pathname);
  const addNoHeaderFooterOrSideNavClass = !showHeader && !showFooter && !showSideNav;

  let topLevelClassName = 'app-main';
  if (!isAuthenticated) {
    topLevelClassName += ' rownd-unauthenticated';
  }
  if (addNoHeaderFooterOrSideNavClass) {
    topLevelClassName += ' rownd-no-header-footer-sidenav';
  }

  return (
    <div className={topLevelClassName}>
      <div className="rownd-body-main rownd-user-welcome-pages-main">
        {showHeader && <Header history={history} module="user" />}
        <div className="rownd-cover-main">
          {showSideNav && <SideNav history={history} pathname={history.location.pathname} />}
          <div className="rownd-main">
            <ToastContainer autoClose={false} />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<PageLoader />}>
                <Switch>
                  <PublicRoute exact path="/" component={SignUpEmail} props={props} />
                  <PublicRoute exact path="/email" component={UserEmail} props={props} />
                  <PublicRoute exact path="/login" component={UserLogin} props={props} />
                  <PrivateRoute exact path="/signup/complete" component={UserSignup} props={props} />
                  <PublicRoute exact path="/signup/verify" component={UserSignup} props={props} />
                  <PublicRoute exact path="/auth/hub/verify" component={HubVerify} props={{ ...props }} noRedirect={true} />
                  {isAuthenticated ? 
                    <PrivateRoute exact path={['/app/:appId', '/apps/:appId']} component={Application} props={props} /> : 
                    <PublicRoute exact path={['/app/:appId', '/apps/:appId']} component={Application} props={props} />
                  }
                  <PrivateRoute exact path="/apps/:appId/:fieldName" component={Application} props={props} />
                  <PrivateRoute exact path="/application/:appId" component={Login} props={props} />
                  <PrivateRoute exact path="/apps" component={ApplicationList} props={props} />
                  <Route component={Error404Page} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
        {showFooter && <Footer />}
      </div>
    </div >
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};

PublicRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};

export default withRouter(EndUser);
