import './Login.scss';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function Login() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { loginWithRedirect } = useAuth0();
  let query = useQuery();

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo: query.get('returnTo') },
    });
  }, [loginWithRedirect, query]);

  return null;
}

export default Login;