import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter
} from 'react-router-dom';
import './UserEmail.scss';
import { connect } from 'react-redux';
import {sendVerificationEmail} from '../../../redux/actions/endUser';
import {iconUrl} from '../../../util/validation';
import { Button } from 'react-bootstrap';


// App start run form App.js
class UserEmail extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    const { application } = this.props;
    if(!application) {
      this.props.history.push('');
    }
  }

  sendEmail = () => {
    const verifyUrl = `${process.env.PUBLIC_URL || window.location.origin}/signup/verify`;
    this.props.sendVerificationEmail({email: this.props.userEmail, verification_url: verifyUrl});
  };

    render() {
      const {application} = this.props;
        return (
            <div className="rownd-user-welcome-pages-main">
            <div className="rownd-welcome-page-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <div className="rownd-welcome-section-main">
                                <div className="">
                                    <h1 className="rownd-project-title-main">Welcome to your Personal Data Center</h1>
                                    <p>ROWND is a secure and transparent data privacy platform for your personal and private data.</p>
                                    { application && (
                                      <p><strong>{application.name}</strong> is partnering with Rownd to safely store your data and allow you to manage it.  They are a part of the data privacy revolution</p>
                                    )}
                                    { application && (
                                      <div className="rownd-american-card-block-main">
                                        <div className="rownd-american-card">
                                          <img src={iconUrl(application.icon)} alt="logo" />
                                        </div>
                                        <p>{application.description}</p>
                                      </div>
                                    )}
                                </div>
                                <div className="">
                                    <p>To access and manage your data, we first have to validate your e-mail address.</p>
                                    <div className="rownd-american-card-block-main">
                                        <div className="rownd-check-icon">
                                            <img src="/images/check-icon-create.svg" alt="logo"/>
                                        </div>
                                        <h6>Great! You will receive an email shortly to create your account.</h6>
                                        <Button variant="link" onClick={() => this.sendEmail()} >Resend email</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

// Prop-types to define.
UserEmail.propTypes = {
  history: PropTypes.object.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
};

// Mapping object to Props.
const mapStateToProps = (state) => {
  return {
    userEmail: state.endUser.userEmail,
    application: state.endUser.application,
    appId: state.endUser.appId,
  };
};


// Mapping function to Props.
const mapDispatchToProps = {
  sendVerificationEmail
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEmail));
