import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './SignUpEmail.scss';
import {required, validateEmail } from '../../../util/validation';
import { Button } from 'react-bootstrap';
import {renderInput} from '../../../util/renderComponent';
import { environment } from '../../../environment.js';
import { errorMessage } from '../../../util/errorComponent';

class SignUpEmail extends Component{

  constructor(props) {
    super(props);
    this.state ={
      socialProfile: {},
      enterEmail: false,
      key:'321',
      loginLinkModal: false,
      loading: false,
      verifyEmailSection: false,
      emailInsights: {},
      loginStrategies: [],
    };
  }

  submitForm = async(values) => {
    this.setState({
      loading: true,
      email: values.email,
    });
    let emailInsights = await this.getEmailInsights(values.email);
    if (!emailInsights?.is_gmail) {
      this.sendVerificationEmail();
    }
    await this.getLoginStrategies();
    this.setState({loading: false});
  };

  getEmailInsights = async(email) => {
    try {
      const { data } = await axios.get(`${environment.API_URL}/users/email-insights`, { params: { email } });
      this.setState({ emailInsights: data });
      return data;
    } catch (err) {
      if (err.response) {
        const { messages } = err.response.data;
        toast.error(errorMessage(messages));
      }
    }
  };

  sendVerificationEmail = async() => {
    this.setState({loading: true});
    let verifyUrl = `${process.env.PUBLIC_URL || window.location.origin}/signup/verify`;
    try {
      await axios.post(`${environment.API_URL}/users/signup/init`, { 
        verification_url: verifyUrl,
        email: this.state.email,
      });
    } catch (err) {
      if (err.response) {
        const { messages } = err.response.data;
        toast.error(errorMessage(messages));
      }
    }
    if (this.state.email !== '') {
      this.setState({verifyEmailSection: true});
    }
    this.setState({loading: false});
  };

  resendEmail = async() => {
    await this.sendVerificationEmail();
  };

  getLoginStrategies = async() => {
    try {
      const { data } = await axios.get(`${environment.API_URL}/users/login-strategies?client_id=${environment.AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/signup/complete`);
      this.setState({ loginStrategies: data.results });
    } catch (err) {
      if (err.response) {
        const { messages } = err.response.data;
        toast.error(errorMessage(messages));
      }
    }
  };

  googleOAuthUrl = () => {
    let strategy = this.state.loginStrategies.find((item) => item.name === 'google-oauth2');
    return strategy?.login_url;
  };

  resetEmail = () => {
    this.setState({
      email: '',
      emailInsights: {},
      verifyEmailSection: false,
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
        return (
          <section className="rownd-login-pages rownd-email-verified-page">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                {this.state.verifyEmailSection && (
                  <div className="rownd-login-page-main">
                    <div className="rownd-login-details">
                      <h1 className="rownd-login-titel-main">Check your email!</h1>
                      <p>We sent you an email to verify your account. Click the link in the Rownd email to finish creating your Rownd account. If you don&#39;t receive an email, click resend.</p>
                    </div>
                      <Button variant="link" onClick={() => this.resetEmail()} className=""><img src="/images/left-arrow-icon-grey.svg" alt=""/><span className="different-email">Enter a different email</span></Button>

                      <div className="rownd-form-btn">
                        <button onClick={() => this.resendEmail()} className="rownd-theme-btns-main">{this.state.loading ? 'Loading...' : 'Resend email'}</button>
                      </div>
                    <p className="signin-link">Already have an account, <NavLink to="/login">Log in</NavLink></p>
                  </div>
                )}

                {!this.state.verifyEmailSection && this.state.emailInsights?.is_gmail && (
                  <div className="rownd-login-page-main">
                    <div className="rownd-login-details">
                      <h1 className="rownd-login-titel-main">Sign up with Google?</h1>
                      <p>It looks like <strong>{this.state.email}</strong> is a Google account. Would you like to continue signing up with Google?</p>
                    </div>
                    <button onClick={() => window.location.href=this.googleOAuthUrl()} className="github-btn google-oauth2">Continue with Google</button>
                    <p>No, thanks. <Button variant="link" onClick={() => this.sendVerificationEmail()} className="">Continue with Email</Button></p>
                  </div>
                )}

                {!this.state.verifyEmailSection && !this.state.emailInsights?.is_gmail && (
                  <div className="rownd-login-page-main">
                    <div className="rownd-login-details">
                      <h1 className="rownd-login-titel-main">Sign up for the Rownd Personal Data Center</h1>
                      <p>Please enter your email to start creating a Rownd Personal Data Center account. After submitting, please check your email to validate it (check your promotions or spam account if you cannot find it right away). </p>
                    </div>


                    <form onSubmit={handleSubmit(this.submitForm)} >


                      <Field name="email" type="email"
                        component={renderInput} label="Email"
                        validate={[validateEmail, required]}
                      />


                      <div className="rownd-form-btn">
                        <button type="submit" disabled={submitting} className="rownd-theme-btns-main rownd-theme-new-btn">{this.state.loading ? 'Loading...' : 'Sign up for Rownd'}</button>
                      </div>
                    </form>
                    <p className="signin-link">Already have an account, <NavLink to="/login">Log in</NavLink></p>
                  </div>
                )}
                  
                  <div className="rownd-manage-footer-link-main mt-5">
                  <p><img src="/images/lock-icon.svg" alt="Rownd" />We use bank level encryption to secure your data. Learn more about our commitment to your <a target="_blank" rel="noopener noreferrer" href="https://rownd.io/privacy">privacy.</a></p>
                  </div>
                </div>
                <div className="col-md-5 offset-md-1 col-lg-6 offset-lg-1 signup-details-block">
                  {/* <div className="login-image-block">
                    <img src="/images/signup-img.png" alt="Home" />
                  </div> */}
                  <div className="col-md-12  mt-5">
                    <div className="row">
                      <div className="col-md-3 image-block">
                        <img src="/images/asset_69.svg" alt="Document illustration with lock icon" />
                      </div>
                      <div className="col-md-9 ">
                        <div className="content">
                          <span className="icon-box-title">Secure:</span>
                          <span className="icon-box-text"> Your Personal data is safe and secure in the Rownd Data Vault</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 image-block">
                        <img src="/images/asset_68.svg" alt="Laptop illustration with a webpage or other information" />
                      </div>
                      <div className="col-md-9 ">
                        <div className="content">
                          <span className="icon-box-title">Easy:</span>

                          <span className="icon-box-text"> Simple to use.  Verify your identity, manage your data, even revoke access</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 image-block">
                          <img src="/images/asset_67.svg" alt="Person illustration connected to their data" />
                      </div>
                      <div className="col-md-9 ">
                        <div className="content">
                          <span className="icon-box-title">Powerful:</span>

                          <span className="icon-box-text"> Reclaim your data privacy rights.  </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </section>
        );
    }
}


SignUpEmail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  module: PropTypes.string.isRequired,
};

export default reduxForm({ form: 'login' })(SignUpEmail);
