import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './SignUp.scss';
import { verifyToken, associateUserToken } from '../../../redux/actions/endUser';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import {errorMessage} from '../../../util/errorComponent';

function SignUp(props) {

  const dispatch = useDispatch();
  const { verifiedEmail, emailError } = useSelector(state => state.endUser);
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const [emailToken, setEmailToken] = useState();
  const [appToClaim, setAppToClaim] = useState();
  const [signupState, setSignupState] = useState('init');

  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(props.location.search);
      const token = searchParams.get('token');
      const app = searchParams.get('app');
      setEmailToken(token);
      setAppToClaim(app);
      dispatch(verifyToken({ verification_token: token }));
    })();
  }, [props.module, props.location.search, dispatch]);

  useEffect(() => {
    if (signupState === 'complete') {
      // Don't run multiple times
      return;
    }
    // Handle signup after verify
    if (props.match.path === '/signup/verify' && verifiedEmail) {

      return loginWithRedirect({
        appState: { returnTo: `/signup/complete?app=${appToClaim}&token=${emailToken}` },
        login_hint: verifiedEmail,
        screen_hint: 'signup',
        redirectUri: `${window.origin}/signup/complete?token=${emailToken}`
      });
    }

    // Handle signup completion
    if (props.match.path === '/signup/complete' && emailToken && isAuthenticated) {
      setSignupState('complete');
      dispatch(associateUserToken(emailToken));
    }
  }, [verifiedEmail, emailToken, isAuthenticated, user, props.match.path, setSignupState, dispatch, loginWithRedirect, signupState, appToClaim]);

  if (signupState === 'complete') {
    return (
      <Redirect to={`/apps${appToClaim ? `/${appToClaim}` : ''}`} />
    );
  }

  if(emailError) {
    toast.error(errorMessage(['verification link is not valid']));
    return (
      <Redirect to="/" />
    );
  }

  return null;
}

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default SignUp;