import './Header.scss';
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { resetUser } from '../../../redux/actions/auth';
import Analytics from '../../../util/Analytics';

export default function Header() {

  let { user, authenticated } = useSelector(state => state.auth);
  let dispatch = useDispatch();
  let auth0 = useAuth0();

  return (
    <header className="rownd-user-welcome-header">
      <div className="container-fluid">
          <div className="rownd-header">

              <NavLink className="rownd-logo" to="/apps">
                  <img src="/images/rownd-logo-white.svg" alt="Rownd"/>
              </NavLink>
              <div className="rownd-welcome-header-right-side">
                {
                    !authenticated && (
                      <NavLink to="/login" className="rownd-learn-more-block">Login</NavLink>
                    )
                }
                {
                    authenticated && (
                        <div className="rownd-user-img">
                            <img src={user && user.picture} alt="User profile" />
                        </div>
                    )
                }
                {
                    authenticated && (
                        <button className="rownd-learn-more-block" onClick={() => {
                        dispatch(resetUser());
                        auth0.logout({
                            returnTo: `${process.env.PUBLIC_URL || window.location.origin}`
                        });
                        }}>
                        Logout
                        </button>
                    )
                }
                </div>
          </div>
      </div>
      <Analytics context="personal-data-center" />
    </header>
  );
}

Header.propTypes = {
  history: PropTypes.any.isRequired,
  module: PropTypes.any.isRequired
};
